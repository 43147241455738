import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';

// import p1 from 'assests/Photos/SchoolProgram/2024/Nobagday/1.jpg';
// import p2 from 'assests/Photos/SchoolProgram/2024/Nobagday/2.jpg';
// import p3 from 'assests/Photos/SchoolProgram/2024/Nobagday/3.jpg';
// import p4 from 'assests/Photos/SchoolProgram/2024/Nobagday/4.jpg';


import { Typography } from '@mui/material';
import Container from 'components/Container';
import { Sidebar2023 } from 'views/SchoolProgram2023/components';
import Sidebar2024 from '../SchoolProgram2023/components/Sidebar2024';
import Archive2022 from '../SchoolProgram2023/components/Archive2022';


const Scintilla2024 = () => {
    const theme = useTheme();
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const openLightbox = (index) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    };

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });


    const [config, setConfig] = useState({ base_image_url: '' });
    useEffect(() => {
        fetch('/config.json')  // For Gatsby, this will resolve to /config.json from the static folder
            .then(response => response.json())
            .then(data => setConfig(data))
            .catch(error => console.error('Error fetching config:', error));
    }, []);

    const p1 = `${config.base_image_url}/home/events-activities/school-programs/Scintilla/1.webp`;
    const p2 = `${config.base_image_url}/home/events-activities/school-programs/Scintilla/2.webp`;
    const p3 = `${config.base_image_url}/home/events-activities/school-programs/Scintilla/3.webp`;
    const p4 = `${config.base_image_url}/home/events-activities/school-programs/Scintilla/4.webp`;
    const p5 = `${config.base_image_url}/home/events-activities/school-programs/Scintilla/5.webp`;
    const p6 = `${config.base_image_url}/home/events-activities/school-programs/Scintilla/6.webp`;
    const p7 = `${config.base_image_url}/home/events-activities/school-programs/Scintilla/7.webp`;
    const p8 = `${config.base_image_url}/home/events-activities/school-programs/Scintilla/8.webp`;
    const p9 = `${config.base_image_url}/home/events-activities/school-programs/Scintilla/9.webp`;
    const p10 = `${config.base_image_url}/home/events-activities/school-programs/Scintilla/10.webp`;
    const p11 = `${config.base_image_url}/home/events-activities/school-programs/Scintilla/11.webp`;
    const p12 = `${config.base_image_url}/home/events-activities/school-programs/Scintilla/12.webp`;
    const p13 = `${config.base_image_url}/home/events-activities/school-programs/Scintilla/13.webp`;


    const photos = [
        {
            src: p1,
            source: p1,
            rows: 1,
            cols: 1,
        },
        {
            src: p2,
            source: p2,
            rows: 1,
            cols: 1,
        },
        {
            src: p3,
            source: p3,
            rows: 1,
            cols: 1,
        },
        {
            src: p4,
            source: p4,
            rows: 1,
            cols: 1,
        },
        {
            src: p5,
            source: p5,
            rows: 1,
            cols: 1,
        },
        {
            src: p6,
            source: p6,
            rows: 1,
            cols: 1,
        },
        {
            src: p7,
            source: p7,
            rows: 1,
            cols: 1,
        },
        {
            src: p8,
            source: p8,
            rows: 1,
            cols: 1,
        },
        {
            src: p9,
            source: p9,
            rows: 1,
            cols: 1,
        },
        {
            src: p10,
            source: p10,
            rows: 1,
            cols: 1,
        },
        {
            src: p11,
            source: p11,
            rows: 1,
            cols: 1,
        },
        {
            src: p12,
            source: p12,
            rows: 1,
            cols: 1,
        },
    {
        src: p13,
        source: p13,
        rows: 1,
        cols: 1,
    }

    ];

    return (
        <Main>
            <Container>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={8}>
                        <Box>

                            <Box >
                                <Typography variant='h4' align='center'>
                                    Scintilla of Art
                                </Typography>
                                <Typography
                                    fontSize="x-small"
                                ><br /><br />
                                    Class: 10      Date: : 24 September 2024
                                </Typography>
                                {/* <Typography
                                variant={'h6'}
                                color={'primary'}
                                align={'center'}
                                fontWeight={'normal'}
                            ><br />
                                “With pure hearts and beaming smiles, our young learners gathered to celebrate the guiding stars in their lives—their cherished teachers.””

                                <br />
                            </Typography> */}

                                <br />
                                <Typography variant={'subtitle1'} align={'justify'}>
                                    On 24 September 2024, the students of class 10 organised an exhibition ‘Scintilla of Art’.
                                    The exhibition comprised of stalls with shimmering Jewellery, colourful Handicrafts,
                                    unique Rangoli’s and vibrant Diyas that were crafted by 10 th grade students. As a part of
                                    the exhibition a group of selected students had the opportunity to set up their very own
                                    NPS Café with 22 teams selling unique delicacies of 35 items. The exhibition was
                                    organised to raise funds for charity.
                                    <br></br>
                                    The exhibition was inaugurated by our Director Ms Manjula Shivshankar and Principal Ms
                                    Sheeja Manoj. Teachers and students visited the various stalls, purchased the items and
                                    thereby contributed to the fund raising efforts. As the day continued the footfall in the
                                    Café increased as all students and teachers wanted to taste the mouth-watering
                                    gourmets. From drinks to desserts the 22 teams served foods from various cuisines and
                                    the enthusiastic foodies did not miss this opportunity to binge on some of their favourite
                                    foods.
                                    <br></br>
                                    The students of Grade 10 took this opportunity to acquire the entrepreneurial skills like
                                    marketing, sales and administration that will be very helpful for their future.
                                    <br></br>


                                </Typography>
                            </Box>
                            {/* <Typography
                                variant={'h6'}
                                color={'primary'}
                                align={'center'}
                                fontWeight={'normal'}
                            ><br />
                                “Embracing the traditions, spreading the love, and creating unforgettable memories."
                                <br />

                            </Typography> */}

                            <Box>
                                <ImageList
                                    variant="quilted"
                                    cols={2}
                                    rowHeight={isMd ? 200 : 100}
                                    gap={isMd ? 16 : 4}
                                >
                                    {photos.map((item, i) => (
                                        <ImageListItem key={i} cols={item.cols} rows={item.rows}>
                                            <LazyLoadImage
                                                height={'100%'}
                                                width={'100%'}
                                                src={item.src}
                                                alt="..."
                                                effect="blur"
                                                onClick={() => openLightbox(i)}
                                                style={{
                                                    objectFit: 'cover',
                                                    filter:
                                                        theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                                                    cursor: 'poiner',
                                                    borderRadius: 8,
                                                }}
                                            />
                                        </ImageListItem>
                                    ))}
                                </ImageList>
                            </Box>
                            {viewerIsOpen && (
                                <Lightbox
                                    mainSrc={photos[currentImage].src}
                                    nextSrc={photos[(currentImage + 1) % photos.length].src}
                                    prevSrc={
                                        photos[(currentImage + photos.length - 1) % photos.length].src
                                    }
                                    onCloseRequest={() => closeLightbox()}
                                    onMovePrevRequest={() =>
                                        setCurrentImage((currentImage + photos.length - 1) % photos.length)
                                    }
                                    onMoveNextRequest={() =>
                                        setCurrentImage((currentImage + 1) % photos.length)
                                    }
                                    reactModalStyle={{ overlay: { zIndex: 1500 } }}
                                />
                            )}
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Box marginBottom={4}>
                            <Sidebar2024 />
                        </Box>
                        <Box marginBottom={4}>
                            <Archive2022 />
                        </Box>

                    </Grid>
                </Grid>
            </Container>
        </Main>

    );
};

export default Scintilla2024;